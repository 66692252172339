<template>
    <section class="position-relative">
        <cargando v-if="loading" />
        <div class="row mx-0 align-items-center mb-3">
            <div class="btn-back" @click="goBack()">
                <i class="icon-left f-12" />
            </div>
            <div class="col-auto text-general f-18 f-500">
                Lanzamiento: {{ data.nombre }}
            </div>
            <div :class="estadosHash[data.estado || 1].color" class="col-auto f-18 f-600 ml-auto">
                <i :class="estadosHash[data.estado || 1].icon" class="f-20" />
                {{ estadosHash[data.estado || 1].tooltip }}
                <span v-if="!_.isEmpty(data) && [2, 3].includes(data.estado)">
                    {{ formatearFecha(data.fecha, 'DD MMM YYYY hh:mm a') }}
                </span>
            </div>
            <div v-if="data.estado == 1" class="btn-general cr-pointer px-2 f-14" @click="programarEnvio()">
                Programar lanzamiento
            </div>
            <el-tooltip placement="bottom" content="Cancelar lanzamiento" effect="light">
                <div v-if="data.estado == 2" class="btn-action border cr-pointer ml-2" @click="modalCancelarLanzamiento()">
                    <i class="icon-cancel-circled-outline f-18" />
                </div>
            </el-tooltip>
            <el-tooltip placement="bottom" content="Editar lanzamiento" effect="light">
                <div v-if="data.estado == 1" class="btn-action border cr-pointer ml-2" @click="editarLanzamiento()">
                    <i class="icon-pencil-outline f-20" />
                </div>
            </el-tooltip>
            <el-tooltip placement="bottom" content="Eliminar lanzamiento" effect="light">
                <div class="btn-action border cr-pointer ml-2" @click="modalEliminarLanzamiento()">
                    <i class="icon-delete-outline f-20" />
                </div>
            </el-tooltip>
        </div>
        <div class="row mx-0 py-2" />
        <div class="row mx-0 bg-white br-t-12 py-3">
            <div class="col-auto text-general f-17 f-500">
                Destinatarios
            </div>
            <div class="col d-flex justify-content-end">
                <template v-if="[1, 2].includes(data.estado)">
                    <el-tooltip placement="bottom" content="Eliminar usuario seleccionados" effect="light">
                        <div class="btn-action cr-pointer border" @click="eliminarDestinatario()">
                            <i class="icon-delete-group f-22" />
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Copiar destinatarios de otros lanzamientos" effect="light">
                        <div class="btn-black border ml-2" @click="copiarDestinatarios()">
                            <i class="icon-content-duplicate f-20" />
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Buscar destinatarios" effect="light">
                        <div class="btn-red ml-2" @click="irBuscarUsuario()">
                            <i class="icon-account-search f-20 f-22" />
                        </div>
                    </el-tooltip>
                </template>
                <div class="col-3">
                    <el-input v-model="buscar" size="small" class="w-100 br-20" placeholder="Buscar Usuario" clearable @change="onSearch()" />
                </div>
            </div>
            <div class="col-12 px-0 mt-3">
                <el-table
                :data="usuarios"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                >
                    <el-table-column
                    v-show="[1, 2].includes(data.estado)"
                    type="selection"
                    width="80"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="nombre"
                    label="Nombre"
                    width="180"
                    class-name="text-center text-general"
                    />
                    <el-table-column
                    prop="count"
                    label="Envíos en esta campaña"
                    width="200"
                    class-name="text-center text-general"
                    />
                    <el-table-column
                    v-if="data.tipo == 1"
                    prop="tienda"
                    :label="`${$config.vendedor} principal`"
                    width="250"
                    class-name="text-general"
                    />
                    <el-table-column
                    prop="cedis"
                    label="Cedis"
                    width="180"
                    class-name="text-general"
                    />
                    <el-table-column>
                        <template slot-scope="{row}">
                            <el-tooltip placement="bottom" content="Elimina destinatario" effect="light">
                                <div v-if="[1, 2].includes(data.estado)" class="btn-action border cr-pointer" @click="eliminarUnDestinatario(row.mu_id)">
                                    <i class="icon-delete-outline f-20" />
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="col-12 px-0">
                    <el-pagination
                    v-show="usuarios.length"
                    background
                    class="text-center paginar-general_mini w-100"
                    :current-page="paginate.current_page"
                    :page-size="paginate.per_page"
                    layout="prev, pager, next"
                    :total="paginate.total"
                    @current-change="verLanzamiento"
                    />
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalUnEliminarUnDestinatario" titulo="Eliminar Destinatario" mensaje="¿Desea eliminar este destinatario?" @eliminar="eliminarUnDest()" />
        <modal-eliminar ref="modalEliminarDestinatario" titulo="Eliminar Destinatario" mensaje="¿Desea eliminar este(os) destinatario(s)?" @eliminar="eliminarDest()" />
        <modal-eliminar ref="modalEliminarLanzamiento" titulo="Eliminar Lanzamiento" mensaje="¿Desea eliminar este lanzamiento?" @eliminar="eliminarLanzamiento()" />
        <modal-eliminar ref="modalCancelarLanzamiento" titulo="Cancelar lanzamiento" mensaje="¿Desea cancelar este lanzamiento?" @eliminar="cancelarLanzamiento()" />
        <modal-editar-lanzamiento ref="modalEditarLanzamiento" @saved="verLanzamiento()" />
        <modal-copiar-destinatarios ref="modalCopiarDestinatarios" @saved="verLanzamiento()" />
        <modal-programar-envio ref="modalProgramarEnvio" @saved="verLanzamiento()" />
    </section>
</template>

<script>

import notificaciones from '~/services/publicidad/notificaciones';
import { mapGetters } from 'vuex'
export default {
    components: {
        modalEditarLanzamiento: () => import('./partials/modalCrearLanzamiento'),
        modalCopiarDestinatarios: () => import('./partials/modalCopiarDestinatarios'),
        modalProgramarEnvio: () => import('./partials/modalProgramarEnvio')
    },
    data(){
        return {
            buscar: '',
            data: {},
            selectioned: [],
            idEli: null,
            loading: false,
            usuarios: [],
            paginate: {},
        }
    },
    computed: {
        ...mapGetters({
            estadosHash: 'publicidad/notificaciones/estadosHash',
        })
    },
    watch:{
        buscar: _.debounce(function(val){ this.onSearch() }, 500)
    },
    mounted(){
        this.verLanzamiento()
    },
    methods: {
        eliminarUnDestinatario(idEli){
            this.idEli = idEli
            this.$refs.modalUnEliminarUnDestinatario.toggle();
        },
        eliminarDestinatario(){
            if(!this.selectioned.length) return this.notificacion('', 'Debe seleccionar al menos un usuario', 'error')
            this.$refs.modalEliminarDestinatario.toggle();
        },
        programarEnvio(){
            this.$refs.modalProgramarEnvio.toggle();
        },
        modalEliminarLanzamiento(){
            this.$refs.modalEliminarLanzamiento.toggle();
        },
        modalCancelarLanzamiento(){
            this.$refs.modalCancelarLanzamiento.toggle();
        },
        editarLanzamiento(){
            let model = {
                nombre: this.data.nombre,
                push: this.data.push,
                mensaje: this.data.mensaje,
            }
            this.$refs.modalEditarLanzamiento.editar(model);
        },
        copiarDestinatarios(){
            this.$refs.modalCopiarDestinatarios.toggle();
        },
        async verLanzamiento(page = 1){
            try {
                this.loading = true
                let params = {
                    page,
                    search: this.buscar
                }
                const { data } = await notificaciones.verLanzamiento(this.$route.params.id_lanzamiento, params );
                this.data = data.lanzamiento
                this.usuarios = data.usuarios.data
                this.paginate = _.omit(data.usuarios, ['data'])
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        handleSelectionChange(selectioned){
            this.selectioned = selectioned
        },
        async eliminarDest(){
            try {
                let ids = this.selectioned.map(el => el.mu_id)
                let model = {
                    ids
                }
                const { data } = await notificaciones.eliminarUsuarioLanzamiento(model);
                if (data.exito){
                    await this.verLanzamiento()
                    this.notificacion('', 'Eliminado correctamente', 'success')
                    this.$refs.modalEliminarDestinatario.toggle();
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarUnDest(){
            try {
                let model = {
                    ids: [this.idEli]
                }
                const { data } = await notificaciones.eliminarUsuarioLanzamiento(model);
                if (data.exito){
                    await this.verLanzamiento()
                    this.notificacion('', 'Eliminado correctamente', 'success')
                    this.$refs.modalUnEliminarUnDestinatario.toggle();
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarLanzamiento(){
            try {
                const { data } = await notificaciones.eliminarLanzamiento(this.$route.params.id_lanzamiento);
                if (data.exito){
                    this.notificacion('', 'Eliminado correctamente', 'success')
                    this.modalEliminarLanzamiento();
                    this.$router.replace({ name: 'admin.publicidad.mensaje-notificacion.campana', params: { id_campana: this.data.id_campana } })

                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async cancelarLanzamiento(){
            try {
                let model = {
                    estado: 4
                }
                const { data } = await notificaciones.cambiarEstadoLanzamiento(model, this.$route.params.id_lanzamiento);
                if (data.exito){
                    await this.verLanzamiento()
                    this.notificacion('', 'Cancelado correctamente', 'success')
                    this.modalCancelarLanzamiento()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        irBuscarUsuario(){
            this.$router.push(
                {
                    name: 'admin.publicidad.mensaje-notificacion.buscar-usuarios',
                    params: this.$route.params
                }
            )
        },
        goBack(){
            let { id_campana } = this.$route.params

            this.$router.push({
                name: 'admin.publicidad.mensaje-notificacion.campana',
                params: { id_campana }
            })
        },
        async onSearch(){
            await this.verLanzamiento()
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-back{
    width: 20px;
    height: 20px;
    color: #FFFFFF;
    background-color: #000000;
}
</style>
