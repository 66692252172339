<template>
    <section>
        <tabs :tabs="rutas" class=" border-bottom pt-2"  />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                {
                    titulo: 'Estadísticas de Leecheros',
                    ruta: 'admin.estadisticas.acceso-usuarios.estadisticas-leecheros'
                },
                {
                    titulo: 'Estadísticas de Clientes',
                    ruta: 'admin.estadisticas.acceso-usuarios.estadisticas-clientes'
                },
                {
                    titulo: 'Comparativa de usuarios',
                    ruta: 'admin.estadisticas.acceso-usuarios.comparativa-usuarios'
                }
            ]
        }
    },
    created(){
        this.rutas[0].titulo = `Estadísticas de ${this.$config.vendedor}`
        this.rutas[1].titulo = `Estadísticas de ${this.$config.cliente}`
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important; 
    &:hover{ color: initial !important;}
}
</style>