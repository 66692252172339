<template>
    <section>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 145px)">
            <div class="row mx-3 mt-5 bg-white br-12 pt-3">
                <div class="col-12">
                    <p class="text-center text-general f-18 f-500">
                        Utilidad de los ultimos 12 meses
                    </p>
                </div>
                <div class="col position-relative">
                    <cargando v-if="cargandoBarras" />
                    <echart :options="utilidad_12_meses" />
                    <div v-if="totalTotal == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                    <div v-if="totalTotal == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
            </div>
            <div class="row mx-3 mt-5 mb-3 justify-content-start">
                <div class="col-auto px-0 d-middle text-general f-500 f-17">
                    Rango de análisis
                </div>
                <div class="col-auto" style="width:174px;">
                    <el-select v-model="rango" placeholder="Seleccionar" size="small">
                        <el-option
                        v-for="item in rangos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto px-0 mx-2" style="width:248px;">
                    <div class="date-picker-edit">
                        <el-date-picker
                        v-model="fecha"
                        class="w-100"
                        size="small"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="disable_fecha"
                        popper-class="date-picker-edit-popper"
                        type="daterange"
                        unlink-panels
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                    </div>
                </div>
                <div class="col-auto">
                    <div class="btn-general cr-pointer text-white p-1 px-3 f-14 br-8 bg-general" @click="informe">
                        Mostrar informe
                    </div>
                </div>
                <cargando v-if="cargandoInformes" mini />
            </div>
            <div class="row mx-3 mt-4 bg-white br-12 pt-3">
                <div class="col-12">
                    <p class="text-general f-18 f-600">
                        Productos que han generado mayor utilidad ( Dinero )
                    </p>
                </div>
                <div class="col mt-4">
                    <div class="row mx-0 prod-utilidad">
                        <div v-for="(data, d) in productos_dinero" :key="d" class="col-auto px-2 ">
                            <el-tooltip placement="bottom" :content="data.nombre" effect="light">
                                <img :src="data.foto" width="115px" height="114px" class="br-12 border obj-cover bg-white" />
                            </el-tooltip>
                            <p class="text-center my-2">
                                {{ separadorNumero(data.utilidad_dinero) }}
                            </p>
                            <p class="text-center text-gr-general f-600 my-2">
                                {{ agregarSeparadoresNumero(data.utilidad_porcentaje,1) }} %
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="productos_dinero_mas > 0" class="col-auto mt-4">
                    <div
                    class="border bg-general3 text-white f-18 br-12 px-4 text-center py-4 cr-pointer"
                    @click="verMas('Productos que han generado mayor utilidad (Dinero)',1)"
                    >
                        Ver Más <br /> <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
            <div class="row mx-3 mt-4 bg-white br-12 pt-3">
                <div class="col-12">
                    <p class="text-general f-18 f-600">
                        Productos que han generado mayor utilidad ( Porcentaje )
                    </p>
                </div>
                <div class="col mt-4">
                    <div class="row mx-0">
                        <div v-for="(data, d) in productos_porcentaje" :key="d" class="col-auto px-2">
                            <el-tooltip placement="bottom" :content="data.nombre" effect="light">
                                <img :src="data.foto" width="115px" height="114px" class="br-12 border obj-cover bg-white" />
                            </el-tooltip>
                            <p class="text-center my-2">
                                {{ separadorNumero(data.utilidad_dinero) }}
                            </p>
                            <p class="text-center text-gr-general f-600 my-2">
                                {{ agregarSeparadoresNumero(data.utilidad_porcentaje,1) }} %
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="productos_porcentaje_mas > 0" class="col-auto mt-4">
                    <div
                    class="border bg-general3 text-white f-18 br-12 px-4 text-center py-4 cr-pointer"
                    @click="verMas('Productos que han generado mayor utilidad (Porcentaje)',2)"
                    >
                        Ver Más <br /> <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
            <div class="row mx-3 mt-4 bg-white br-12 pt-3">
                <div class="col-12">
                    <p class="text-general f-18 f-600">
                        Categorias que han generado mayor utilidad ( Dinero )
                    </p>
                </div>
                <div class="col mt-4">
                    <div class="row mx-0">
                        <div v-for="(data, d) in categorias_dinero" :key="d" class="col-auto px-2">
                            <div class="br-12 border d-middle-center px-3 f-12" style="width:125px;height:75px;background-color:#F8F9FF;">
                                {{ data.nombre }}
                            </div>
                            <p class="text-center my-2">
                                {{ separadorNumero(data.utilidad_dinero) }}
                            </p>
                            <p class="text-center text-gr-general f-600 my-2">
                                {{ agregarSeparadoresNumero(data.utilidad_porcentaje,1) }} %
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="categorias_dinero_mas > 0" class="col-auto mt-4">
                    <div
                    class="border bg-general3 text-white f-18 br-12 px-4 text-center py-2 cr-pointer"
                    @click="verMas('Categorias que han generado mayor utilidad ( Dinero )',3)"
                    >
                        Ver Más <br /> <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
            <div class="row mx-3 mt-4 bg-white br-12 pt-3">
                <div class="col-12">
                    <p class="text-general f-18 f-600">
                        {{ $config.vendedor }} que han generado mayor utilidad ( Dinero )
                    </p>
                </div>
                <div class="col mt-4">
                    <div class="row mx-0">
                        <div v-for="(data, d) in tiendas_dinero" :key="d" class="col-auto px-2">
                            <el-tooltip placement="bottom" :content="data.nombre" effect="light">
                                <img :src="data.foto" width="115px" height="114px" class="br-12 border obj-cover bg-white" />
                            </el-tooltip>
                            <p class="text-center my-2">
                                {{ separadorNumero(data.utilidad_dinero) }}
                            </p>
                            <p class="text-center text-gr-general f-600 my-2">
                                {{ agregarSeparadoresNumero(data.utilidad_porcentaje,1) }} %
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="tiendas_dinero_mas > 0" class="col-auto mt-4">
                    <div
                    class="border bg-general3 text-white f-18 br-12 px-4 text-center py-4 cr-pointer"
                    @click="verMas('Leecheros que han generado mayor utilidad ( Dinero)',4)"
                    >
                        Ver Más <br /> <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
            <div class="row mx-3 mt-4 bg-white br-12 pt-3">
                <div class="col-12">
                    <p class="text-general f-18 f-600">
                        {{ $config.vendedor }} que han generado mayor utilidad ( Porcentaje )
                    </p>
                </div>
                <div class="col mt-4">
                    <div class="row mx-0">
                        <div v-for="(data, d) in tiendas_porcentaje" :key="d" class="col-auto px-2">
                            <el-tooltip placement="bottom" :content="data.nombre" effect="light">
                                <img :src="data.foto" width="115px" height="114px" class="br-12 border obj-cover bg-white" />
                            </el-tooltip>
                            <p class="text-center my-2">
                                {{ separadorNumero(data.utilidad_dinero) }}
                            </p>
                            <p class="text-center text-gr-general f-600 my-2">
                                {{ agregarSeparadoresNumero(data.utilidad_porcentaje,1) }} %
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="tiendas_porcentaje_mas > 0" class="col-auto mt-4">
                    <div
                    class="border bg-general3    text-white f-18 br-12 px-4 text-center py-4 cr-pointer"
                    @click="verMas('Leecheros que han generado mayor utilidad (Porcentaje)',5)"
                    >
                        Ver Más <br /> <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
            <div class="row mx-3 mt-4 bg-white br-12 pt-3">
                <div class="col-12">
                    <p class="text-general f-18 f-600">
                        Cedis que han generado mayor utilidad ( Dinero )
                    </p>
                </div>
                <div class="col mt-4">
                    <div class="row mx-0">
                        <div v-for="(data, d) in cedis_dinero" :key="d" class="col-auto px-2">
                            <el-tooltip placement="bottom" :content="data.nombre" effect="light">
                                <img :src="data.foto" width="115px" height="114px" class="br-12 border obj-cover bg-white" />
                            </el-tooltip>
                            <p class="text-center my-2">
                                {{ separadorNumero(data.utilidad_dinero) }}
                            </p>
                            <p class="text-center text-gr-general f-600 my-2">
                                {{ agregarSeparadoresNumero(data.utilidad_porcentaje,1) }} %
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="cedis_dinero_mas > 0" class="col-auto mt-4">
                    <div
                    class="border bg-general3 text-white f-18 br-12 px-4 text-center py-4 cr-pointer"
                    @click="verMas('Cedis que han generado mayor utilidad (Dinero)',6)"
                    >
                        Ver Más <br /> <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-ver-mas ref="VerMas" :titulo="titulo_modal" />
    </section>
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import Estadisiticas from "../../services/estadisticas";
let colorGeneral1 = getComputedStyle(document.documentElement).getPropertyValue('--color-general2')

import moment from 'moment'
import Echart from '~/components/Echart.vue';
Vue.component('echarts',echarts);


const changeSize = (width, array, categoria = false) => {
    const original = _.cloneDeep(array)
    let size = array.length
    if (width <= 1350 && width > 1088){
        size = categoria ? 5 : 6
    }
    else if(width <= 1088){
        size = categoria ? 3 : 4
    }
    return original.slice(0, size);
}

export default {
    components: {
        modalVerMas: () => import('./partials/modalVerMas')
    },
    data(){
        let self = this

        return{
            window: {
                width: 0,
                height: 0
            },
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            cargandoInformes: true,
            cargandoBarras: true,
            utilidad_12_meses: {
                grid: {
                    /* containLabel: true */
                },
                tooltip: {
                    show: true,
                    formatter: function(params){
                        return `${params.data[0]} <br>
                        Utilidad: ${self.separadorNumero(params.data[1])}<br>
                        Porcentaje: ${self.agregarSeparadoresNumero(params.data[2])}%<br>
                        Pedidos: ${self.agregarSeparadoresNumero(params.data[3])}`
                    }
                },
                xAxis: {
                    type: 'category',
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                },
                yAxis: {
                    splitLine: {
                        show:false,
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                    scale: true,
                    axisLabel: {
                        formatter: (value) => `${this.moneda.sigla} ${this.separadorNumero(value, 0, false)}`
                    }
                },
                dataset:{
                    source: []
                },
                series: [
                    {
                        name: '',
                        type: 'scatter',
                        symbolSize: function(data){
                            return data[4] * 0.66 ;
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        itemStyle: {
                            /* shadowBlur: 10,
                            shadowColor: 'rgba(120, 36, 50, 0.5)',
                            shadowOffsetY: 5, */
                            color: colorGeneral1,
                            /* shadowOffsetY: 5,
                            normal:{
                                color: '#FF1720'
                            } */
                        }
                    }
                ]
            },
            productos_dinero: [],
            productos_dinero_original: [],
            productos_dinero_mas: 0,
            productos_porcentaje: [],
            productos_porcentaje_original: [],
            productos_porcentaje_mas: 0,
            categorias_dinero: [],
            categorias_dinero_original: [],
            categorias_dinero_mas: 0,
            tiendas_dinero: [],
            tiendas_dinero_original: [],
            tiendas_dinero_mas: 0,
            tiendas_porcentaje: [],
            tiendas_porcentaje_original: [],
            tiendas_porcentaje_mas: 0,
            cedis_dinero: [],
            cedis_dinero_original: [],
            cedis_dinero_mas: 0,
            titulo_modal: '',
            totalTotal: 0
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            moneda:'cedis/moneda',
        }),
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;
            default:
                break;
            }
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            this.cargando()
            this.montar()
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            if(val == null) return
            this.cargando()
            this.montar()
        },
    },
    created(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    mounted(){
        this.montar()
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.cargandoBarras = true
            }
            this.cargandoInformes = true
        },
        handleResize(){
            this.productos_dinero = changeSize(window.innerWidth, this.productos_dinero_original)
            this.productos_porcentaje = changeSize(window.innerWidth, this.productos_porcentaje_original)
            this.categorias_dinero = changeSize(window.innerWidth, this.categorias_dinero_original, true)
            this.tiendas_porcentaje = changeSize(window.innerWidth, this.tiendas_porcentaje_original)
            this.tiendas_dinero = changeSize(window.innerWidth, this.tiendas_dinero_original)
        },
        async montar(){
            try {
                if(this.id_moneda == null || this.id_cedis == null ){
                    setTimeout(async() => {
                        await this.utilidad_x_meses()
                        await this.informe()
                    }, 1000);
                }else{
                    await this.utilidad_x_meses()
                    await this.informe()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        verMas(string, tipo){
            this.titulo_modal = string;
            let params = {
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                tipo,
                id_cedis: this.id_cedis,
                id_moneda: this.id_moneda,
            }
            this.$refs.VerMas.toggle(params);
        },
        async utilidad_x_meses(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const {data} = await Estadisiticas.utilidad_x_meses(params)
                this.utilidad_12_meses.dataset.source = data.pedidos_x_mes
                this.totalTotal = data.totalTotal
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargandoBarras = false
            }
        },
        async informe(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                this.loading = true
                const { data } = await Estadisiticas.informe(params)

                this.productos_dinero_original = data.productos_dinero
                this.productos_dinero = changeSize(window.innerWidth, data.productos_dinero);
                this.productos_dinero_mas = data.productos_dinero_mas

                this.productos_porcentaje_original = data.productos_porcentaje
                this.productos_porcentaje = changeSize(window.innerWidth, data.productos_porcentaje)
                this.productos_porcentaje_mas = data.productos_porcentaje_mas

                this.categorias_dinero_original = data.categorias_dinero
                this.categorias_dinero = changeSize(window.innerWidth, data.categorias_dinero, true)
                this.categorias_dinero_mas = data.categorias_dinero_mas

                this.tiendas_dinero_original = data.tiendas_dinero
                this.tiendas_dinero = changeSize(window.innerWidth, data.tiendas_dinero)
                this.tiendas_dinero_mas = data.tiendas_dinero_mas

                this.tiendas_porcentaje_original = data.tiendas_porcentaje
                this.tiendas_porcentaje = changeSize(window.innerWidth, data.tiendas_porcentaje)
                this.tiendas_porcentaje_mas = data.tiendas_porcentaje_mas

                this.cedis_dinero_original = data.cedis_dinero
                this.cedis_dinero = changeSize(window.innerWidth, data.cedis_dinero)
                this.cedis_dinero_mas = data.cedis_dinero_mas

            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargandoInformes = false
            }
        },
        async informes(){
            try {
                this.tipo = 1
                this.cargando(false)

                await this.informe()
            } catch (error){
                console.log(error);
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px;
}
.img-sindatos{
        position: absolute;
        z-index: 3;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        left: 0px;
        width: 100%;
        height: 100%;
        img{
            // border-radius: 50%;
            /* max-height: 100%; */
            box-shadow: 0px 3px 6px #00000029;
            max-width: 50%;
            height: 50%;
        }
        .mensaje{
            color: #fff;
            text-shadow: 0 2px 3px #000000;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
