<template>
    <section>
        <div style="height:calc(100vh - 101px);">
            <tabs :tabs="rutas" class="border-bottom " />
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    middleware:['auth'],

    data(){
        return {
            rutas: [
                { titulo: 'Dashboard', ruta: 'admin.estadisticas.estadisticas', can:'tab1_estadisticas_general'},
                { titulo: 'Utilidad en Ventas', ruta: 'admin.estadisticas.utilidad', can: 'tab_estadisticas_utilidad_ventas' },
                { titulo: 'Etapas de pedidos', ruta: 'admin.estadisticas.etapas', can:'tab1_estadisticas_etapas' },
                { titulo: 'Acceso de usuarios', ruta: 'admin.estadisticas.acceso-usuarios', can:'tab1_estadisticas_accesos' } ,
                { titulo: 'Movimientos de productos', ruta: 'admin.estadisticas.movimientos-productos', can:'tab1_estadisticas_movimientos-productos' },
                { titulo: 'Reclamos de leecheros', ruta: 'admin.estadisticas.dashboard-reclamos', can:'tab1_estadisticas_dashboard_reclamos' },
                { titulo: 'Inventarios', ruta: 'admin.estadisticas.movimientos', can:'tab1_estadisticas_movimientos-inventarios' },
                { titulo: 'KPI', ruta: 'admin.estadisticas.kpi', can:'tab1_estadisticas_kpi' },
            ]
        }
    },
    watch: {
        '$route.name'(){
            this.$store.commit('cedis/setCargandoTerminado', true)
        }
    },
    mounted(){
        this.rutas[5].titulo = `Reclamos de ${this.$config.vendedor}`
    },
}
</script>

<style lang="scss" scoped>
.br-t-12{
    border-radius: 12px 12px 0px 0px;
}
</style>
